.hamburger-icon {
  @include hamburger($primary-color, $secondary-color, 20px, 16px, 2px, 3);
}


.hero {
  background: $primary-color;
  height: 300px;
  margin-bottom: 3rem;
  position: relative;

  .hero-content {
    text-align: center;
    background: $white;
    padding: 1rem;
    width: 50%;
    @include absolute-center;
  }
}

h1, h2, h3, h4 {
  letter-spacing: 0.25rem;
}

.mittag {
  margin-bottom: 0.5rem;
}

.primary-bg {
  background: $primary-color;
  color: $light-gray;

  a {
    color: $light-gray;
  }

  ul.accordion {
    color: $black;
  }
  //height: 400px;
  .card-divider {
    background: $secondary-color;
    color: $light-gray;
  }

  .pricing-table .title {
    background: $secondary-color;
  }

  .pricing-table .button {
    background: $secondary-color;
    color: $light-gray;
  }

  .callout.primary {
    ul.accordion {
      background: #d9efe3;
      color: #0a0a0a;
      $accordion-item-background-hover: primary-color;

      .accordion-title {
        background: $primary-color;
      }
      .accordion-content {
        background: #d9efe3;
      }
    }
  }
}

.secondary-bg {
  background: $secondary-color;
  color: $light-gray;

  table {
    color: $black;
  }

  a {
    color: $light-gray;
  }
  //height: 400px;
}

.gray-bg {
  background: $light-gray;

  .card-divider {
    background: $secondary-color;
    color: $light-gray;
  }
}

.my-row {
  @include flex-grid-row(collapse, expand, null, true, $grid-column-gutter) { }
}

.my-nested-row {
  @include flex-grid-row(nest collapse, expand, null, true, $grid-column-gutter) { }
  padding: 1.5rem;
}

.my-h1 {
  color: $light-gray;
  text-align: right;
}

.my-light-font {
  color: $light-gray;
}

.my-callout-right {
  @include callout-base();
  background: $primary-color;
  border-top: 5px solid rgba($white, 1.0);
  border-bottom: 5px solid rgba($white, 1.0);
  border-right: 5px solid rgba($white, 1.0);
  border-left: 3px solid rgba($white, 1.0);
}

.my-callout-left {
  @include callout-base();
  background: $secondary-color;
  border-top: 5px solid rgba($white, 1.0);
  border-bottom: 5px solid rgba($white, 1.0);
  border-right: 3px solid rgba($white, 1.0);
  border-left: 5px solid rgba($white, 1.0);
}

// Navigation
$topbar-center-logo-bg: $topbar-background;

.topbar-center-logo {
  background: $topbar-center-logo-bg;

  .menu {
    background: $topbar-center-logo-bg;

    a {
      color: $primary-color;
    }
  }
}

.topbar-center-logo .top-bar-center {
  flex: 1 0 auto;

  @include breakpoint(small only) {
    display: none;
  }
}

.topbar-center-logo-mobile {
  background: $topbar-center-logo-bg;

  .menu-icon {
    @include hamburger($primary-color, $secondary-color)
    vertical-align: text-top;
  }
}

.my-card {
  @include card-container($primary-color, $light-gray, 10px, None, 15px, 10px 10px 5px #888888);

}


// Startseite
.start-content-header {
  font-size: 2.5rem;
  letter-spacing: 0.25rem;
  padding: 1.5rem 0 0;
  a {
    border-bottom: 0.5px dotted rgba(255, 255, 255, 0.3);
  }
  a:hover {
    border-bottom: 0.5px dotted white;
  }
}

.start-content-h3 {
  a:hover {
    border-bottom: 0.5px dotted white;
  }
  a {
    border-bottom: 0.5px dotted rgba(255, 255, 255, 0.3);
  }
}

.start-content-img {
  padding: 1.5rem 0 1.5rem;
}

.start-content-description {
  font-size: 1.25rem;
}


// Mittagstisch - table
// stack for small with header
$header: true;
.stack-mt {
  @include table-stack($header);
}
